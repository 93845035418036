/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Markdown from 'react-markdown';

import ContentfulRichText from '../components/ContentfulRichText';
// eslint-disable-next-line import/no-cycle
import Layout from '../components/layout';
import Container from '../components/Container';
import { ContentWrap } from '../components/Global';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import SocialShare from '../components/SocialMedia/SocialShare';
import { Popover } from '../components/Popover';
import MediaBreakUp from '../styles/MediaBreakUp';
import SimplifyTeamWork from '../components/SimplifyTeamWork';
import BlogPostContext from '../components/Context/BlogPostContext';

const Avatar = styled(GatsbyImage)`
  margin: 0;
  border-radius: 50%;
  margin: 0 0.25rem;
  width: ${props => props.width} !important;
  height: ${props => props.height} !important;
`;

const Article = styled.article`
  display: block;
  margin-top: 30px;

  a {
    color: #5986ff;
    margin-left: 0.3rem;

    &:hover {
      color: #4d74dc;
      text-decoration: underline;
    }
  }

  header span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 300;
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 0.5rem 0;
  }

  li {
    display: inline;
  }

  section {
    max-width: 41.5rem;
    margin: auto;
  }

  ${MediaBreakUp.xl`
    section {
      margin-top: 2.387rem;
      margin-bottom: 18.125rem;
      max-width: 46.625rem;
    }
  `}
  ${MediaBreakUp.md`
    margin-top: 16px;
  `}
`;

const AuthorWrapper = styled.div`
  position: relative;

  div {
    display: none;
  }

  &:hover {
    div {
      display: block;
    }
  }
`;

const BlogPostTemplate = ({ data, location }) => {
  const post = data.contentfulBlogPost;
  const seoTags = post.tags
    ? post.tags.map(tag => ({
      property: 'article:tag',
      content: tag,
    }))
    : [];
  const seoMeta = {
    title: post.seoTitle || `${post.title} | Blog | Workast`,
    description: post.seoDescription ? post.seoDescription.seoDescription : post.excerpt.excerpt
  };
  return (
    <BlogPostContext.Provider value={post}>
      <Layout
        location={location}
        showScrollProgressBar
        title={post.title}
      >
        <SEO
          title={seoMeta.title}
          description={seoMeta.description}
          type="article"
          meta={seoTags}
          image={post.featuredImage}
          author={post.author.name}
          date={{
            published: post.publishDateISO,
            modified: post.updatedAtISO,
          }}
        />
        <Container>
          <Article>
            <Hero featuredImage={post.featuredImage} reverse>
              <header>
                <h1>{post.title}</h1>
                <span>
                  By
                  {post.author.image && (
                    <Avatar
                      image={getImage(post.author.image)}
                      alt={`${post.author.name} avatar`}
                      height="20px"
                      width="20px"
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  )}
                  <AuthorWrapper>
                    <Link
                      to={`/author/${post.author.slug}/`}
                      style={{ marginRight: '0.3rem' }}
                    >
                      {post.author.name}
                    </Link>
                    <Popover>
                      {post.author.image && (
                        <Avatar
                          fixed={post.author.image.fixed}
                          alt={`${post.author.name} avatar`}
                          height="80px"
                          width="80px"
                          objectFit="cover"
                          objectPosition="50% 50%"
                        />
                      )}
                      <h3>{post.author.name}</h3>
                      <Markdown
                        allowedTypes={['link', 'paragraph', 'text']}
                        linkTarget="_blank"
                      >
                        {post.author.shortBio.shortBio}
                      </Markdown>
                    </Popover>
                  </AuthorWrapper>
                  {`on ${post.publishDate}`}
                  {post.category && (
                    <Link to={`/blog/${post.category[0].slug}/`}>
                      {`#${post.category[0].slug}`}
                    </Link>
                  )}
                </span>
              </header>
            </Hero>
          </Article>
          <ContentWrap>
            <SocialShare />
            <ContentfulRichText richText={post.body} />
            <div className="section">
              <SimplifyTeamWork />
            </div>
          </ContentWrap>
        </Container>
      </Layout>
    </BlogPostContext.Provider>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string
      }),
      tags: PropTypes.arrayOf(PropTypes.string),
      category: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
      publishDate: PropTypes.string,
      publishDateISO: PropTypes.string,
      updatedAtISO: PropTypes.string,
      featuredImage: PropTypes.shape({
        title: PropTypes.string,
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          sizes: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcSetWebp: PropTypes.string,
          srcWebp: PropTypes.string,
        }),
        openGraph: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fixed: PropTypes.shape({
              base64: PropTypes.string,
              height: PropTypes.number,
              width: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
            }),
          }),
        }),
      }),
      excerpt: PropTypes.shape({
        excerpt: PropTypes.string,
      }),
      body: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        childContentfulRichText: PropTypes.object,
      }),
      author: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        shortBio: PropTypes.shape({ shortBio: PropTypes.string }),
        company: PropTypes.string,
        image: PropTypes.shape({
          fixed: PropTypes.shape({
            base64: PropTypes.string,
            height: PropTypes.number,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            srcSetWebp: PropTypes.string,
            srcWebp: PropTypes.string,
            width: PropTypes.number,
          }),
        }),
      }),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      id
      title
      excerpt {
        excerpt
      }
      tags
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData
          }
          ... on ContentfulElementButton {
            __typename
            contentful_id
            buttonText: text
            link
          }
          ... on ContentfulElementImageColumn {
            __typename
            contentful_id
            title
            left
            image {
              gatsbyImageData(width:1000)
            }
          }
          ... on ContentfulElementQuote {
            __typename
            contentful_id
            company
            companyUrl
            author
            role
            text {
              text
            }
          }
          ... on ContentfulElementTip {
            __typename
            contentful_id
            text {
              text
            }
          }
        }
      }
      author {
        name
        shortBio {
          shortBio
        }
        company
        slug
        image {
          gatsbyImageData(width:80)
        }
      }
      category {
        slug
      }
      featuredImage {
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
      publishDate(formatString: "MMMM DD")
      publishDateISO: publishDate
      updatedAtISO: updatedAt
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`;
