import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import Icon from '../Icon';

const Wrapper = styled.ul`
  display: flex;
  justify-content: flex-end;

  li.social-item {
    margin: 5px 0 2px;
    display: flex;

    &:not(:last-child) {
      margin: 5px 0.8rem 2px 0;
    }
  }
`;

const ShareIcon = styled.a`
  padding: 0.3rem;
  background-color: #8c8c8c;
  border-radius: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #656565;
  }
`;
const iconColor = '#ffffff';

const SocialShare = () => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query componentQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const ASCIIUrl = encodeURIComponent(
    `${data.site.siteMetadata.siteUrl}${pathname}`
  );

  return (
    <Wrapper>
      <li className="social-item">
        <ShareIcon
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${ASCIIUrl}`}
          aria-label="Share this post on LinkedIn"
        >
          <Icon name="linkedIn" color={iconColor} />
        </ShareIcon>
      </li>
      <li className="social-item">
        <ShareIcon
          href={`https://www.facebook.com/sharer/sharer.php?u=${ASCIIUrl}`}
          aria-label="Share this post on Facebook"
        >
          <Icon name="facebook" color={iconColor} />
        </ShareIcon>
      </li>
      <li className="social-item">
        <ShareIcon
          href={`https://twitter.com/intent/tweet?url=${ASCIIUrl}+via+%40workast`}
          aria-label="Share this post on Twitter"
        >
          <Icon name="twitter" color={iconColor} />
        </ShareIcon>
      </li>
    </Wrapper>
  );
};

export default SocialShare;
