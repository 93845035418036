import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PopoverWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;
  display: block;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 17.25rem;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 1.437rem;
  z-index: 4;

  img {
    border-radius: 50%;
    margin: 0 0 1rem 0;
    width: 4.87rem;
    height: 4.87rem;
  }

  h3 {
    font-size: 24px;
    line-height: 29px;
    margin: 1rem 0 0.7rem 0;
  }

  p {
    margin: 0px;
  }

  @media (min-width: 990px) {
    display: flex;
  }
`;

export const Popover = ({ children }) => (
  <PopoverWrapper>{children}</PopoverWrapper>
);

Popover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default { Popover };
